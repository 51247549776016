.folderbuttonWrapper {
  float: right;
  justify-content: flex-end;
  right: 0;
}

.folderbutton {
  display: flex;
  height: 37px;
  justify-content: center;
  align-items: center;
  padding: 10px 33px 10px 33px;
  border-radius: 3px;
}
